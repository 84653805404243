import React from "react"
import theme from "theme"
import { Theme, Text, Section, Box, Image, LinkBox } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"services"} />
      <Helmet>
        <title>Nasze usługi | Poznaj nasze usługi w Space Waves Spa</title>
        <meta
          name={"description"}
          content={"Wellness na miarę dla każdej duszy"}
        />
        <meta
          property={"og:title"}
          content={"Nasze usługi | Poznaj nasze usługi w Space Waves Spa"}
        />
        <meta
          property={"og:description"}
          content={"Wellness na miarę dla każdej duszy"}
        />
        <meta
          property={"og:image"}
          content={"https://yoxinex.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://yoxinex.com/img/letter_Y_blue-512.webp"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://yoxinex.com/img/letter_Y_blue-512.webp"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://yoxinex.com/img/letter_Y_blue-512.webp"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://yoxinex.com/img/letter_Y_blue-512.webp"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://yoxinex.com/img/letter_Y_blue-512.webp"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://yoxinex.com/img/letter_Y_blue-512.webp"}
        />
      </Helmet>
      <Components.Header />
      <Section padding="80px 0" sm-padding="40px 0">
        <Override slot="SectionContent" align-items="center" />
        <Text font="--headline1" md-font="--headline2" margin="20px 0 0 0">
          Spektrum przestrzeni
        </Text>
        <Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
          Zanurz się w sercu spokoju i dobrego samopoczucia w Space Waves. Nasz
          kompleksowy zakres usług został zaprojektowany tak, aby zaspokoić
          każdy aspekt podróży wellness. Od momentu przekroczenia naszych drzwi,
          wkraczasz na spersonalizowaną ścieżkę do spokoju, zdrowia i
          odmłodzenia. Każda usługa, opracowana z fachową wiedzą i troską, ma na
          celu zharmonizowanie ciała, umysłu i ducha.
        </Text>
      </Section>
      <Section
        padding="20px 0 80px 0"
        lg-padding="60px 0 60px 0"
        md-padding="40px 0 40px 0"
        sm-padding="30px 0 30px 0"
      >
        <Box
          margin="0px 0px 50px 0px"
          display="flex"
          flex-direction="column"
          align-items="flex-start"
        >
          <Text
            font="--headline2"
            color="--dark"
            text-align="center"
            md-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
            margin="16px 0px 50px 0px"
          >
            Nasze usługi
          </Text>
        </Box>
        <LinkBox
          display="flex"
          margin="0px 0px 32px 0px"
          md-flex-direction="column"
          md-align-items="center"
          md-justify-content="center"
          flex-direction="row"
          align-items="flex-start"
          flex-wrap="wrap"
          lg-margin="0px 0px 16px 0px"
        >
          <Box
            width="40%"
            padding="0px 0px 0px 16px"
            display="flex"
            align-items="flex-start"
            justify-content="flex-start"
            md-width="100%"
            sm-align-items="flex-start"
            sm-flex-direction="column"
            sm-justify-content="flex-start"
            flex-direction="column"
            lg-width="100%"
            lg-flex-direction="row"
            lg-justify-content="space-between"
            lg-padding="16px 12px 16px 12px"
          >
            <Box
              padding="0px 50px 0px 0px"
              lg-width="70%"
              sm-width="100%"
              lg-padding="0px 0px 0px 0px"
            >
              <Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
                Masaże lecznicze: Zrelaksuj się dzięki naszej gamie masaży,
                które koją mięśnie, łagodzą napięcie i przywracają równowagę.
              </Text>
              <Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
                - Masaż szwedzki - klasyczna technika łagodzenia stresu i
                relaksu.
                <br />
                - Masaż tkanek głębokich - celuje w głębokie warstwy mięśni, aby
                złagodzić przewlekły ból.
                <br />- Masaż aromaterapeutyczny - łączy olejki eteryczne z
                terapią masażu, zapewniając zmysłową podróż.
              </Text>
            </Box>
          </Box>
          <Box
            width="60%"
            padding="0px 16px 0px 0px"
            align-items="flex-start"
            display="flex"
            justify-content="flex-start"
            margin="0px 0px 0px 0px"
            md-width="100%"
            lg-width="100%"
            lg-padding="0px 0px 0px 0px"
          >
            <Image
              src="https://yoxinex.com/img/3.jpg"
              border-radius="24px"
              max-width="100%"
              width="100%"
              object-fit="none"
              max-height="400px"
              sm-max-height="213px"
            />
          </Box>
        </LinkBox>
        <LinkBox
          display="flex"
          margin="0px 0px 32px 0px"
          md-flex-direction="column"
          md-align-items="center"
          md-justify-content="center"
          flex-direction="row"
          align-items="flex-start"
          flex-wrap="wrap"
          lg-margin="0px 0px 16px 0px"
        >
          <Box
            width="60%"
            padding="0px 16px 0px 0px"
            align-items="flex-start"
            display="flex"
            justify-content="flex-start"
            margin="0px 0px 0px 0px"
            md-width="100%"
            lg-width="100%"
            lg-padding="0px 0px 0px 0px"
          >
            <Image
              src="https://yoxinex.com/img/4.jpg"
              border-radius="24px"
              max-width="100%"
              width="100%"
              object-fit="none"
              max-height="400px"
              sm-max-height="213px"
            />
          </Box>
          <Box
            width="40%"
            padding="0px 0px 0px 16px"
            display="flex"
            align-items="flex-start"
            justify-content="flex-start"
            md-width="100%"
            sm-align-items="flex-start"
            sm-flex-direction="column"
            sm-justify-content="flex-start"
            flex-direction="column"
            lg-width="100%"
            lg-flex-direction="row"
            lg-justify-content="space-between"
            lg-padding="16px 12px 16px 12px"
          >
            <Box
              padding="0px 50px 0px 0px"
              lg-width="70%"
              sm-width="100%"
              lg-padding="0px 0px 0px 0px"
            >
              <Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
                Rewitalizująca pielęgnacja skóry: Promień dzięki naszym
                indywidualnym zabiegom na twarz i pielęgnacji skóry,
                zaprojektowanym dla każdego rodzaju skóry.
              </Text>
              <Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
                - Hydrating Facial - głęboko nawilża skórę, pozostawiając ją
                miękką i gładką.
                <br />
                - Zabieg przeciwstarzeniowy - pomaga zmniejszyć oznaki
                starzenia, promując młodzieńczy blask.
                <br />- Detoksykujący zabieg na skórę - oczyszcza skórę i nadaje
                jej odświeżony wygląd.
              </Text>
            </Box>
          </Box>
        </LinkBox>
        <LinkBox
          display="flex"
          margin="0px 0px 32px 0px"
          md-flex-direction="column"
          md-align-items="center"
          md-justify-content="center"
          flex-direction="row"
          align-items="flex-start"
          flex-wrap="wrap"
          lg-margin="0px 0px 16px 0px"
        >
          <Box
            width="40%"
            padding="0px 0px 0px 16px"
            display="flex"
            align-items="flex-start"
            justify-content="flex-start"
            md-width="100%"
            sm-align-items="flex-start"
            sm-flex-direction="column"
            sm-justify-content="flex-start"
            flex-direction="column"
            lg-width="100%"
            lg-flex-direction="row"
            lg-justify-content="space-between"
            lg-padding="16px 12px 16px 12px"
          >
            <Box
              padding="0px 50px 0px 0px"
              lg-width="70%"
              sm-width="100%"
              lg-padding="0px 0px 0px 0px"
            >
              <Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
                Detoksykacja i sauna: Oczyść swoje ciało i umysł w naszych
                najnowocześniejszych saunach.
              </Text>
              <Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
                - Sauna tradycyjna - zachęca do detoksykacji poprzez pot i
                relaks.
                <br />
                - Łaźnia parowa - wykorzystuje wilgotne ciepło do oczyszczania
                skóry i układu oddechowego.
                <br />- Sauna na podczerwień - delikatna opcja, która promuje
                głęboki relaks tkanek.
              </Text>
            </Box>
          </Box>
          <Box
            width="60%"
            padding="0px 16px 0px 0px"
            align-items="flex-start"
            display="flex"
            justify-content="flex-start"
            margin="0px 0px 0px 0px"
            md-width="100%"
            lg-width="100%"
            lg-padding="0px 0px 0px 0px"
          >
            <Image
              src="https://yoxinex.com/img/5.jpg"
              border-radius="24px"
              max-width="100%"
              width="100%"
              object-fit="none"
              max-height="400px"
              sm-max-height="213px"
            />
          </Box>
        </LinkBox>
        <LinkBox
          display="flex"
          margin="0px 0px 32px 0px"
          md-flex-direction="column"
          md-align-items="center"
          md-justify-content="center"
          flex-direction="row"
          align-items="flex-start"
          flex-wrap="wrap"
          lg-margin="0px 0px 16px 0px"
        >
          <Box
            width="60%"
            padding="0px 16px 0px 0px"
            align-items="flex-start"
            display="flex"
            justify-content="flex-start"
            margin="0px 0px 0px 0px"
            md-width="100%"
            lg-width="100%"
            lg-padding="0px 0px 0px 0px"
          >
            <Image
              src="https://yoxinex.com/img/6.jpg"
              border-radius="24px"
              max-width="100%"
              width="100%"
              object-fit="none"
              max-height="400px"
              sm-max-height="213px"
            />
          </Box>
          <Box
            width="40%"
            padding="0px 0px 0px 16px"
            display="flex"
            align-items="flex-start"
            justify-content="flex-start"
            md-width="100%"
            sm-align-items="flex-start"
            sm-flex-direction="column"
            sm-justify-content="flex-start"
            flex-direction="column"
            lg-width="100%"
            lg-flex-direction="row"
            lg-justify-content="space-between"
            lg-padding="16px 12px 16px 12px"
          >
            <Box
              padding="0px 50px 0px 0px"
              lg-width="70%"
              sm-width="100%"
              lg-padding="0px 0px 0px 0px"
            >
              <Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
                Przestrzeń przy basenie: Zanurz się w spokojnych wodach naszego
                basenu.
              </Text>
              <Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
                - Lap Pool - Zaprojektowany dla tych, którzy szukają
                orzeźwiającego treningu wodnego.
                <br />
                - Basen rekreacyjny - spokojne miejsce do relaksu i łagodnych
                ćwiczeń.
                <br />- Jacuzzi - Ciepła, bulgocząca woda, która koi mięśnie i
                uspokaja umysł.
              </Text>
            </Box>
          </Box>
        </LinkBox>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-11"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="50%"
          lg-width="100%"
          lg-align-items="center"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          flex-direction="column"
          align-items="flex-start"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
        >
          <Text
            sm-font="--headline3"
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            md-text-align="left"
          >
            Rozpocznij swoją podróż z nami - Twoja ścieżka do dobrego
            samopoczucia czeka
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            sm-margin="0px 0px 30px 0px"
            md-text-align="left"
          >
            W Space Waves jesteśmy oddani zapewnieniu środowiska, które
            pielęgnuje uzdrawianie, zachęca do relaksu i promuje dobre
            samopoczucie. Nasz wachlarz usług i zabiegów został zaprojektowany
            tak, aby zaspokoić Twoje wyjątkowe potrzeby, zapewniając
            spersonalizowane i głębokie doświadczenie. Aby dowiedzieć się więcej
            o naszej ofercie lub wyruszyć w podróż spokoju, zapraszamy do
            kontaktu. Odwiedź nas, aby odkryć głębię odnowy biologicznej, którą
            zapewniamy i stworzyć własną ścieżkę do przestrzeni i zdrowia.
          </Text>
        </Box>
        <Box
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
        >
          <Box
            width="100%"
            height="auto"
            padding="0px 0px 70% 0px"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            overflow-x="hidden"
            overflow-y="hidden"
            position="relative"
            transform="translateY(0px)"
          >
            <Image
              position="absolute"
              width="100%"
              top="auto"
              left={0}
              src="https://yoxinex.com/img/7.jpg"
              object-fit="cover"
              display="block"
              right={0}
              bottom="0px"
              min-height="100%"
            />
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
